<template>
  <div class="base-line-chart">
    <LineChart
      class="chart"
      :chart-data="chartConfig.data"
      :options="chartConfig.options"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { LineChart } from "vue-chart-3";
import { Chart as ChartJS, registerables } from "chart.js";

ChartJS.register(...registerables);

export default defineComponent({
  name: "BaseLineChart",
  components: {
    LineChart,
  },
  props: {
    chartConfig: {},
  },
});
</script>

<style>
.base-line-chart .chart {
  height: 350px;
}
</style>
