<template>
  <v-container fluid>
    <v-row>
      <v-col cols="1" sm="1">
        <v-btn block color="primary" large @click="setTimePeriod('thisYear')"
          >THIS YEAR
        </v-btn>
      </v-col>
      <v-col cols="1" sm="1">
        <v-btn block color="primary" large @click="setTimePeriod(lastYear)"
          >LAST YEAR
        </v-btn>
      </v-col>
      <v-col cols="1" sm="1">
        <v-btn block color="primary" large @click="setTimePeriod(twoYearsAgo)"
          >{{ twoYearsAgo }}
        </v-btn>
      </v-col>
      <v-col cols="1" sm="1">
        <v-btn block color="primary" large @click="setTimePeriod(threeYearsAgo)"
          >{{ threeYearsAgo }}
        </v-btn>
      </v-col>
      <v-col cols="1" sm="1">
        <v-btn block color="primary" large @click="setTimePeriod(fourYearsAgo)"
          >{{ fourYearsAgo }}
        </v-btn>
      </v-col>
      <v-col cols="1" sm="1">
        <v-btn block color="primary" large @click="setTimePeriod(fiveYearsAgo)"
          >{{ fiveYearsAgo }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-data-table
          v-if="!loading"
          :disable-pagination="false"
          :disable-sort="true"
          :hide-default-footer="true"
          :items-per-page="1"
          :items="result || []"
          item-key="name"
        >
          <template v-slot:item="data">
            <span style="padding: 5px">
              <v-chip>
                {{ data.item.year }}
              </v-chip>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12">
        <v-skeleton-loader
          v-if="loading"
          ref="skeleton"
          :boilerplate="false"
          :tile="false"
          class="mx-auto"
          type="list-item-three-line"
        ></v-skeleton-loader>
        <v-card v-if="!loading && result">
          <v-card-text>
            <BaseLineChart
              :chart-config="{
                data: chartData,
                options: {
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      min: 0,
                    },
                  },
                },
              }"
            />
            <div class="toggle-wrapper">
              <v-btn-toggle v-model="toggleChart" borderless dense>
                <v-btn value="hour"> Hour </v-btn>
                <v-btn value="percentage"> Percentage </v-btn>
              </v-btn-toggle>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12">
        <v-skeleton-loader
          v-if="loading"
          ref="skeleton"
          :boilerplate="false"
          :tile="false"
          class="mx-auto"
          type="list-item-three-line"
        ></v-skeleton-loader>
        <v-data-table
          v-if="!loading && result"
          :disable-pagination="true"
          :headers="headers"
          :hide-default-footer="true"
          :items="result"
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:[`item.feature`]="{ item }">
            {{ humanTimeCustom(item.feature) }}
            {{ item.featurePer }}
          </template>
          <template v-slot:[`item.assistance`]="{ item }">
            {{ humanTimeCustom(item.assistance) }}
            {{ item.assistancePer }}
          </template>
          <template v-slot:[`item.error`]="{ item }">
            {{ humanTimeCustom(item.error) }}
            {{ item.errorPer }}
          </template>
          <template v-slot:[`item.sum`]="{ item }">
            {{ humanTimeCustom(item.sum) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { AnalyticsApi, TimeSpentMonthlyResponse } from "@/api";
import { parseTimeCustom, parseTimeChart } from "@/tools/humanTime";
import BaseLineChart from "@/components/BaseLineChart.vue";

const ERROR_COLOR = "#CC0033";
const FEATURE_COLOR = "#5CB85C";
const ASSISTANCE_COLOR = "#428BCA";

export default defineComponent({
  name: "TimeSpentMonthlyView",
  components: {
    BaseLineChart,
  },
  data() {
    return {
      loading: false as boolean,
      dateFrom: "" as string,
      dateFromMenu: false as boolean,
      dateTo: "" as string,
      lastYear: (new Date().getFullYear() - 1) as number,
      twoYearsAgo: (new Date().getFullYear() - 2) as number,
      threeYearsAgo: (new Date().getFullYear() - 3) as number,
      fourYearsAgo: (new Date().getFullYear() - 4) as number,
      fiveYearsAgo: (new Date().getFullYear() - 5) as number,
      dateToMenu: false as boolean,
      result: null as Array<TimeSpentMonthlyResponse> | null,
      singleSelect: null,
      headers: [
        { text: "Month", value: "month", width: "150px" },
        { text: "Feature", value: "feature", width: "200px", align: "end" },
        {
          text: "Assistance",
          value: "assistance",
          width: "200px",
          align: "end",
        },
        { text: "Error", value: "error", width: "200px", align: "end" },
        { text: "Sum", value: "sum", width: "200px", align: "end" },
      ],
      //chart
      toggleChart: "hour",
    };
  },
  computed: {
    chartData() {
      if (!this.result || this.result.length === 0) {
        return {
          labels: [],
          datasets: [],
        };
      }
      const labels: string[] = [];
      const datasets: any[] = [
        {
          label: "Error",
          backgroundColor: ERROR_COLOR,
          borderColor: ERROR_COLOR,
          data: [],
        },
        {
          label: "Feature",
          backgroundColor: FEATURE_COLOR,
          borderColor: FEATURE_COLOR,
          data: [],
        },
        {
          label: "Assistance",
          backgroundColor: ASSISTANCE_COLOR,
          borderColor: ASSISTANCE_COLOR,
          data: [],
        },
      ];
      this.result.forEach((item: any) => {
        labels.push(`${item.year}-${item.month}`);
        let errorData = 0;
        let featureData = 0;
        let assistanceData = 0;
        if (this.toggleChart === "hour") {
          errorData = this.chartTime(item.error);
          featureData = this.chartTime(item.feature);
          assistanceData = this.chartTime(item.assistance);
        } else if (this.toggleChart === "percentage") {
          errorData = Number(
            item.errorPer.replace("(", "").replace(")", "").replace("%", "")
          );

          featureData = Number(
            item.featurePer.replace("(", "").replace(")", "").replace("%", "")
          );

          assistanceData = Number(
            item.assistancePer
              .replace("(", "")
              .replace(")", "")
              .replace("%", "")
          );
        }
        datasets[0].data.push(errorData);
        datasets[1].data.push(featureData);
        datasets[2].data.push(assistanceData);
      });
      return {
        labels,
        datasets,
      };
    },
  },
  methods: {
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        this.result = (
          await new AnalyticsApi().getAnalyticsTimeSpentMonthly(
            this.dateFrom,
            this.dateTo
          )
        ).data;
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },

    setTimePeriod(period: string | number) {
      const today = new Date();
      let thisYear = today.getFullYear();
      switch (period) {
        case "thisYear":
          this.dateFrom = thisYear + "-01-01";
          this.dateTo = thisYear + "-12-31";
          break;
        default:
          this.dateFrom = period + "-01-01";
          this.dateTo = period + "-12-31";
          break;
      }
      this.loadData();
    },
    humanTimeCustom(time: number) {
      return parseTimeCustom(time);
    },
    chartTime(time: number) {
      return parseTimeChart(time);
    },
    loadDataDefault() {
      this.loadData();
    },
  },
});
</script>

<style>
.toggle-wrapper {
  margin-top: 12px;
  text-align: center;
}
</style>
