import humanizeDuration, { HumanizerOptions } from "humanize-duration";

const humanizeDurationOptions: HumanizerOptions = {
  language: navigator.language.split(/[-_]/)[0],
  units: ["h", "m"],
};

export function parseTime(time: number): string {
  if (time === 0) return "";
  return humanizeDuration(time * 1000, humanizeDurationOptions);
}

export function parseTimeCustom(time: number): string {
  let str = "";

  if (time === 0) return str;

  const uHours = time % 3600;
  const uMinutes = time % 60;
  const hours = (time - uHours) / 3600;
  const minutes = (time - hours * 3600 - uMinutes) / 60;

  if (hours > 0) str += hours + "h";
  if (hours > 0 && minutes > 0) str += ", ";
  if (minutes > 0) str += minutes + "m";

  return str;
}

export function parseTimeChart(time: number): number {
  if (time === 0) return 0;

  const uHours = time % 3600;
  const uMinutes = time % 60;
  const hours = (time - uHours) / 3600;
  const minutes = (time - hours * 3600 - uMinutes) / 60;

  const minute = (minutes / 60) * 100;
  return Number((Math.round((hours + minute) * 100) / 100).toFixed(2));
}

export function checkEstimateTime(
  estimate: number | null,
  timeTotal: number | null,
  mode: string
): string {
  if ("" === mode)
    if (timeTotal && estimate)
      if (estimate >= timeTotal) return parseTimeCustom(estimate);
      else return "";
    else return "";

  if (timeTotal && estimate)
    if (estimate < timeTotal) return parseTimeCustom(estimate);

  return "";
}
