<template>
  <div class="navigation">
    <v-navigation-drawer v-model="drawer" app>
      <router-link :to="'/'">
        <v-icon class="menu-logo" size="64"> mdi-hammer-screwdriver</v-icon>
      </router-link>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.link"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-img
          v-if="$route.name !== 'home'"
          class="mx-10"
          src="/images/logo-jf.svg"
          @click="$vuetify.theme.dark = !$vuetify.theme.dark"
        ></v-img>
        <p class="text-center caption">
          {{ mainStore.env.version }}
        </p>
      </template>
    </v-navigation-drawer>
    <v-app-bar app dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-icon class="ml-4 mr-2">{{ icon }}</v-icon>
      <span v-if="$route.name !== 'home'">{{ title }}</span>
      <v-spacer></v-spacer>
      <v-icon class="mr-2">mdi-account-circle</v-icon>
      {{ mainStore.env.user?.name }}
    </v-app-bar>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useMainStore } from "@/store/main";

interface NavItem {
  id: number;
  title: string;
  icon: string;
  link: NavItemLink;
}
interface NavItemLink {
  name: string;
}

export default defineComponent({
  name: "NavigationBar",
  props: {
    source: String,
  },
  data() {
    return {
      drawer: true,
      items: [
        {
          id: 0,
          title: "TimeSpent Auswertung",
          icon: "mdi-chart-bar",
          link: {
            name: "TimeSpent",
          },
        },
        {
          id: 0,
          title: "Monat Auswertung",
          icon: "mdi-chart-line",
          link: {
            name: "TimeSpent-Monthly",
          },
        },
      ] as NavItem[],
    };
  },
  computed: {
    title: function (): string | null | undefined {
      let item = this.items.find((element) => {
        return element.link.name === this.$route.name;
      });
      return item ? item.title : this.$route.name;
    },
    icon: function (): string | null | undefined {
      let item = this.items.find((element) => {
        return element.link.name === this.$route.name;
      });
      return item ? item.icon : null;
    },
  },
  setup() {
    return {
      mainStore: useMainStore(),
    };
  },
});
</script>

<style lang="scss">
.menu-logo {
  width: 100%;
  &.theme--light {
    color: black;
  }
}
</style>
